.blog-wrap {
    margin-top: 44px;

    .blog-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        border-bottom: 1px solid $border-color-lite;

        .section-title {
            padding-bottom: 14px;
        }

        .view-all {
            margin-top: 2px;
            color: $color-gray-dark;

            &:hover {
                color: $color-default;
                color: var(--color-primary);
            }
        }
    }
}

.blog-post {
    width: calc(33.33333333333333% - 30px);
    margin: 0 15px;
    border: 1px solid $color-gray-lite;
    border-radius: $radius-default;
    transition: $transition-default;

    &:hover {
        border-color: transparent;
        box-shadow: 2.5px 4.33px 50px 5px rgba(11, 28, 42, 0.15);

        .blog-image {
            .image-placeholder {
                border-color: #f6f7f8;
            }
        }
    }

    .blog-image {
        position: relative;
        margin: -1px -1px 0 -1px;

        > img {
            width: 100%;
            border-radius: $radius-default $radius-default 0 0;
        }

        .image-placeholder {
            display: flex;
            height: 227px;
            justify-content: center;
            align-items: center;
            background: #f6f7f8;
            border: 1px solid $color-gray-lite;
            border-bottom: none;
            border-radius: $radius-default $radius-default 0 0;
            transition: $transition-default;

            > img {
                height: 100px;
                width: 100px;
            }
        }

        .blog-date {
            position: absolute;
            left: 20px;
            bottom: 20px;
            height: 55px;
            width: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: $color-default-transparent;
            background: var(--color-primary-transparent);
            border-radius: $radius-default;

            > span {
                font-weight: 300;
                line-height: 18px;
                color: $color-white;
            }
        }
    }

    .blog-meta {
        display: flex;
        margin-bottom: 8px;

        > li {
            font-size: 14px;
            font-weight: 300;
            color: $color-gray;
            margin-left: 25px;
            cursor: default;

            &:first-child {
                margin-left: 0;
            }

            > i {
                margin-right: 5px;
                color: $color-default;
                color: var(--color-primary);
            }
        }
    }

    .blog-body {
        padding: 26px 20px 30px;
    }

    .blog-title {
        font-weight: 400;
        margin-bottom: 13px;

        > a {
            color: $color-black;

            &:hover {
                color: $color-default;
                color: var(--color-primary);
            }
        }
    }

    .blog-description {
        font-weight: 300;
        line-height: 22px;
        max-height: 66px;
        margin-bottom: 29px;
        color: $color-gray;
        overflow: hidden;
    }
}

@media screen and (max-width: $xl) {
    .blog-post {
        .blog-image {
            .image-placeholder {
                height: 200px;
            }
        }
    }
}

@media screen and (max-width: $lg) {
    .blog-post {
        width: calc(50% - 30px);

        &:last-child {
            margin-top: 30px;
        }
    }
}

@media screen and (max-width: $md) {
    .blog-post {
        width: 100%;

        &:nth-child(2) {
            margin-top: 30px;
        }
    }
}
