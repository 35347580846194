.product-card {
    position: relative;
    display: flex !important;
    height: auto;
    margin: 0 15px;
    padding: 20px 20px 0;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid $color-gray-lite;
    border-radius: $radius-default;
    transition: $transition-primary;

    &:hover {
        border-color: $color-white;
        box-shadow: 2.5px 4.33px 50px 5px rgba(11, 28, 42, 0.15);
        z-index: 1;

        .product-card-actions {
            button {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
            }
        }

        .product-badge {
            opacity: 0;
            visibility: hidden;
            transform: scale(0.9);
        }

        .product-card-countdown {
            opacity: 0;
            visibility: hidden;
            transform: translate(-50%, -50%) scale(0.9);
        }

        .product-rating {
            opacity: 0;
            visibility: hidden;
        }

        .product-name {
            transform: translateY(-33px);
        }

        .product-card-bottom {
            border-top-color: transparent;
        }

        .product-price {
            opacity: 0;
            visibility: hidden;

            &:after {
                opacity: 0;
                visibility: hidden;
            }
        }

        .product-price-clone {
            opacity: 1;
            visibility: visible;
            transform: translateX(-15px);

            &:after {
                opacity: 1;
                visibility: visible;
                transform: translateX(20px);
            }
        }

        .btn-add-to-cart {
            opacity: 1;
            visibility: visible;
            transform: scale(1);

            &.disabled,
            &[disabled] {
                opacity: 0.65;
            }
        }
    }

    .product-card-top {
        position: relative;
    }

    .product-image {
        height: 180px;

        .image-placeholder {
            height: 85px;
            width: 85px;
        }
    }

    .product-badge {
        right: 0;
        z-index: 1;
        transition: $transition-primary;
    }

    .product-card-actions {
        position: absolute;
        top: -5px;
        right: 0;
        display: flex;
        flex-direction: column;
        border-radius: $radius-default;
        z-index: 1;

        .btn {
            height: 35px;
            width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            background: $color-white;
            border: 1px solid $color-gray-lite;
            border-radius: $radius-default;
            opacity: 0;
            visibility: hidden;
            transform: scale(0.5);
            transition: opacity $transition-primary, transform $transition-primary, border-color $transition-default;

            &:hover {
                border-color: $color-default-transparent-lite;
                border-color: var(--color-primary-transparent-lite);
            }

            > i {
                font-size: 15px;
            }
        }

        .btn-wishlist {
            margin-bottom: 5px;
        }
    }

    .product-card-countdown {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1);
        transition: $transition-primary;

        &.is-countdown {
            .countdown-section {
                width: auto;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .countdown-amount {
                height: 38px;
                width: 38px;
                margin: 0;
                color: $color-white;
                background: $color-default-transparent;
                background: var(--color-primary-transparent);
            }

            .countdown-period {
                display: none;
            }
        }
    }

    .product-card-middle {
        position: relative;
        display: flex;
        height: 90px;
        margin-top: auto;
        padding-bottom: 9px;
        flex-direction: column;
        justify-content: flex-end;
        z-index: 0;
    }

    .product-rating {
        margin-bottom: 10px;
        transition: $transition-primary;

        .back-stars {
            font-size: 13px;
        }
    }

    .product-name {
        display: block;
        color: $color-black;
        transition: $transition-primary;
        z-index: 1;

        h6 {
            position: relative;
            font-weight: 400;
            line-height: 21px;
            max-height: 40px;
            display: -webkit-box;
            background: $color-white;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .product-card-bottom {
        position: relative;
        padding: 12px 0 10px;
        border-top: 1px solid $color-gray-lite;
        transition: $transition-default;
    }

    .product-price {
        position: relative;
        transition: $transition-primary;

        &:after {
            position: absolute;
            content: '';
            left: -20px;
            top: 4px;
            height: 15px;
            width: 7px;
            background: $color-default;
            background: var(--color-primary);
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            transition: $transition-primary;
        }
    }

    .product-price-clone {
        position: absolute;
        left: 15px;
        bottom: 15px;
        padding: 0;
        border: none;
        opacity: 0;
        visibility: hidden;
        z-index: -1;

        &:after {
            left: -40px;
            top: 4px;
            opacity: 0;
            visibility: hidden;
        }
    }

    .btn-add-to-cart {
        font-size: 13px;
        position: absolute;
        left: 0;
        bottom: 20px;
        width: 100%;
        padding: 6px 0 4px;
        opacity: 0;
        visibility: hidden;
        transform: scale(0.9);
        transition: $transition-primary;

        > i {
            font-size: 15px;
            margin: 5px 6px 0 0;
            vertical-align: top;
        }
    }
}

@media screen and (max-width: 1800px) {
    .product-card {
        .product-image {
            height: 160px;
        }
    }
}

@media screen and (max-width: $lg) {
    .product-card {
        margin: 0 8px;
        padding: 15px 15px 0;

        &:hover {
            border-color: $color-gray-lite;
            box-shadow: none;

            .product-badge {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
            }

            .product-card-countdown {
                opacity: 1;
                visibility: visible;
                transform: translate(-50%, -50%);
            }

            .product-rating {
                opacity: 1;
                visibility: visible;
            }

            .product-name {
                transform: none;
            }

            .product-price {
                opacity: 1;
                visibility: visible;
            }
        }

        .product-card-top {
            margin-bottom: 10px;
        }

        .product-image {
            position: relative;
            height: 80%;
            padding-bottom: 80%;

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .product-card-actions,
        .product-price-clone,
        .btn-add-to-cart {
            display: none;
        }

        .product-card-middle {
            height: 60px;
            padding-bottom: 0;
        }

        .product-rating {
            margin-bottom: 5px;
        }

        .product-card-bottom {
            padding-top: 5px;
            border-top: none;
        }

        .product-price {
            flex-direction: column;

            &:after {
                content: none;
            }

            .previous-price {
                margin: -4px auto 0 0;
            }
        }
    }
}
