@import 'order-summary/_order-summary-top',
        'order-summary/_order-summary-middle',
        'order-summary/_order-summary-bottom';

.order-summary-wrap {
    width: 380px;
    min-width: 380px;
    padding-left: 15px;
}

.order-summary {
    padding: 24px 30px 30px;
    background: $color-white-dark;
    border-radius: $radius-default;

    .price-amount {
        font-weight: 500;
    }
}

@media screen and (max-width: $lg) {
    .order-summary-wrap {
        width: 100%;
        min-width: 0;
        margin-top: 50px;
        padding-left: 0;
    }
}
