/* fonts */

$primary-font: 'Rubik', Arial, Helvetica, sans-serif;

/* default theme colors */

$color-default: #0068e1;
$color-default-hover: #0055b8;
$color-default-transparent: rgba(0, 85, 184, 0.8);
$color-default-transparent-lite: rgba(0, 85, 184, 0.3);

/* colors */

$color-white: #ffffff;
$color-white-lite: #f2f4f5;
$color-white-dark: #f9f9f9;
$color-white-transparent: rgba(255, 255, 255, 0.7);
$color-black: #191919;
$color-gray: #6e6e6e;
$color-gray-lite: #ebebeb;
$color-gray-dark: #a6a6a6;
$color-red: #e23e1d;
$color-green: #1eb48e;
$color-info: #247cc6;
$color-yellow: #ffb136;
$border-color: #e1e1e1;
$border-color-lite: #e5e5e5;

/* screen size */

$xl: 1199px;
$lg: 991px;
$md: 767px;
$sm: 576px;

/* miscellaneous */

$radius-default: 2px;
$transition-default: 150ms ease-in-out;
$transition-primary: 200ms ease-in-out;
