.navigation-inner {
    display: flex;
    align-items: center;
    border: 1px solid $border-color;
    border-radius: $radius-default;

    .navbar {
        padding: 0;
        z-index: 2;

        .navbar-nav {
            margin: 0 30px;
        }
    }

    .navigation-text {
        margin-left: auto;
        margin-right: 25px;
        color: $color-yellow;
    }
}

.category-nav {
    position: relative;
    width: 262px;
    min-width: 262px;
    display: flex;
    padding: 4px 0 4px 4px;
    align-items: center;
    align-self: stretch;

    &.show {
        .category-nav-inner {
            cursor: default;
        }

        .category-dropdown-wrap {
            display: block;
        }
    }

    .category-nav-inner {
        font-weight: 500;
        line-height: 24px;
        width: 100%;
        display: flex;
        padding: 14px 35px 13px 30px;
        color: $color-white;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        background: $color-default;
        background: var(--color-primary);
        border-radius: $radius-default 0 0 $radius-default;
        cursor: pointer;

        &:after {
            display: none;
        }

        > i {
            font-size: 16px;
        }
    }

    .category-dropdown-wrap {
        position: absolute;
        left: -1px;
        top: calc(100% + 31px);
        display: none;
        width: 263px;
        background: $color-white;
        border: 1px solid $border-color;
        border-top: none;
        border-bottom: none;
        border-radius: $radius-default;
        z-index: 4;

        &:before,
        &:after {
            position: absolute;
            content: '';
            left: 0;
            height: 1px;
            width: 100%;
            background: $border-color;
        }

        &:before {
            top: 0;
        }

        &:after {
            bottom: 0;
        }

        &.show {
            display: block;
        }

        .category-dropdown {
            &:before,
            &:after {
                position: absolute;
                content: '';
                left: 50%;
                border-style: solid;
                transform: translateX(-50%);
                z-index: 1;
            }

            &:before {
                top: -25px;
                border-width: 13px 15px;
                border-color: transparent;
                border-bottom-color: $border-color;
            }

            &:after {
                top: -23px;
                border-width: 12px 14px;
                border-color: transparent;
                border-bottom-color: $color-white;
            }
        }
    }
}

.vertical-megamenu {
    position: static;
    padding: 15px 0;

    > li {
        &.hide {
            display: none;
        }

        &.more-categories {
            > .menu-item {
                font-weight: 500;
            }
        }

        &:hover {
            > .menu-item {
                font-weight: 500;
                color: $color-default;
                color: var(--color-primary);

                &:after {
                    opacity: 1;
                    visibility: visible;
                }

                > .menu-item-icon {
                    color: $color-default;
                    color: var(--color-primary);
                }

                > i {
                    color: $color-default;
                    color: var(--color-primary);
                }
            }
        }

        > .menu-item {
            position: relative;
            line-height: 46px;
            display: block;
            padding: 0 50px 0 24px;
            color: $color-black;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: none;

            &:after {
                position: absolute;
                content: '';
                left: 0;
                top: 15px;
                height: 15px;
                width: 7px;
                background: $color-default;
                background: var(--color-primary);
                opacity: 0;
                visibility: hidden;
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
                transition: $transition-default;
            }

            > .menu-item-icon {
                font-size: 16px;
                display: inline-flex;
                margin: 15px 10px 0 0;
                color: $color-gray;
                vertical-align: top;
                transition: $transition-default;
            }

            > i {
                font-size: 12px;
                position: absolute;
                top: 17px;
                right: 25px;
                color: $color-gray;
                transition: $transition-default;
            }
        }
    }

    &.mega-menu {
        .dropdown {
            &:hover {
                > .sub-menu {
                    animation: menu-in-left 250ms ease;
                }
            }

            > .sub-menu {
                right: -175px;
                top: 0;
                animation: menu-in-right 250ms ease;
            }
        }

        > .fluid-menu {
            &:hover {
                > .fluid-menu-wrap {
                    animation: menu-in-left 250ms ease;
                }
            }

            > .fluid-menu-wrap {
                top: 0;
                right: -720px;
                animation: menu-in-right 250ms ease;
            }
        }
    }
}

.horizontal-megamenu {
    position: relative;
    flex-wrap: wrap;

    &.mega-menu {
        > .fluid-menu {
            position: relative;
        }
    }

    > .nav-item {
        &:hover {
            > .nav-link {
                font-weight: 500;
                color: $color-default;
                color: var(--color-primary);

                > i {
                    right: 12px;
                    color: $color-default;
                    color: var(--color-primary);
                }
            }
        }

        &.multi-level,
        &.fluid-menu {
            > .nav-link {
                padding-right: 35px;
            }
        }

        > .nav-link {
            line-height: 36px;
            position: relative;
            margin-bottom: -1px;
            padding: 12px 15px 12px;
            color: $color-black;
            white-space: nowrap;
            transition: none;

            &:after {
                content: attr(data-text);
                font-weight: 500;
                display: block;
                height: 0;
                overflow: hidden;
                visibility: hidden;
            }

            > i {
                position: absolute;
                top: 50%;
                right: 15px;
                color: $color-gray;
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }

    &.mega-menu {
        > .dropdown.multi-level {
            > .sub-menu {
                left: 0;
                top: 59px;
            }
        }

        .dropdown {
            &:hover {
                > .sub-menu {
                    animation: menu-in-top 250ms ease;
                }
            }

            > .sub-menu {
                left: 100%;
                top: 0;
                animation: menu-in-bottom 250ms ease;
            }
        }

        > .fluid-menu {
            &:hover {
                > .fluid-menu-wrap {
                    animation: menu-in-top 250ms ease;
                }
            }

            > .fluid-menu-wrap {
                top: 59px;
                left: 0;
                animation: menu-in-bottom 250ms ease;
            }
        }
    }
}

.rtl {
    .horizontal-megamenu {
        > .nav-item {
            &:hover {
                > .nav-link {
                    > i {
                        left: 15px #{'/*rtl:ignore*/'};
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .category-nav {
        width: 245px;
        min-width: 245px;

        .category-dropdown-wrap {
            width: 246px;
        }
    }
}

@media screen and (max-width: 1300px) {
    .navigation-inner {
        .navigation-text {
            display: none;
        }
    }
}

@media screen and (max-width: 1047px) {
    .vertical-megamenu {
        &.mega-menu {
            > .fluid-menu {
                > .fluid-menu-wrap {
                    right: -696px;
                    width: 696px;
                }
            }
        }
    }
}

@media screen and (max-width: $lg) {
    .navigation-wrap {
        display: none;
    }

    .navigation-inner {
        .navbar {
            .navbar-nav {
                margin-left: 0;
            }
        }
    }

    .category-nav {
        display: none;

        .category-dropdown-wrap {
            display: none;
        }
    }
}
