.banner-wrap {
    margin-top: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &.m-t-0 {
        margin-top: 0;
    }
}

.banner {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 0;

    &:before,
    &:after {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.3);
        opacity: 0;
        z-index: 1;
    }

    &:before {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &:after {
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }

    &:hover {
        &:before {
            animation: effect-height 700ms ease-in;
            animation-fill-mode: forwards;
        }

        &:after {
            animation: effect-width 700ms ease-in;
            animation-fill-mode: forwards;
        }

        > img {
            transform: scale(1.05);
        }
    }

    > img {
        width: 100%;
        border-radius: $radius-default;
        transform: scale(1);
        transition: 800ms ease;
    }
}

@media screen and (max-width: $md) {
    .three-column-full-width-banner {
        .row {
            > div {
                &:first-child {
                    .banner {
                        margin-top: 0;
                    }
                }

                .banner {
                    margin-top: 15px;
                }
            }
        }
    }

    .two-column-banner {
        .row {
            > .col-md-9 {
                &:last-child {
                    .banner {
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    .three-column-banner {
        .row {
            > .col-md-6 {
                &:first-child {
                    .banner {
                        margin-top: 0;
                    }
                }

                .banner {
                    margin-top: 15px;
                }
            }
        }
    }
}

@keyframes effect-height {
    0% {
        height: 0;
        opacity: 1;
        visibility: visible;
    }

    100% {
        height: 100%;
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes effect-width {
    0% {
        width: 0;
        opacity: 1;
        visibility: visible;
    }

    100% {
        width: 100%;
        opacity: 0;
        visibility: hidden;
    }
}
