.my-reviews-table {
	margin-bottom: 10px;

	tbody {
		td {
			&:not(:first-child) {
				vertical-align: middle;
			}

			&:nth-child(4) {
                white-space: nowrap;
			}
		}
	}

	.product-image {
		height: 60px;
		width: 60px;
		border-radius: $radius-default;
        overflow: hidden;

		.image-placeholder {
			height: 40px;
			width: 40px;
		}
	}

	.product-name {
        display: inline-block;
        min-width: 220px;
		color: $color-black;

		&:hover {
			color: $color-default;
            color: var(--color-primary);
		}
	}

	.product-rating {
		> i {
			font-size: 18px;
			margin-right: 4px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
