@import 'header/_header-left',
        'header/_header-search',
        'header/_searched-keywords',
        'header/_search-suggestion',
        'header/_header-right',
        'header/_sticky-header';

.header-wrap {
    position: relative;
    z-index: 8;
}

.header-wrap-inner {
    padding: 40px 0;
}

@media screen and (max-width: $lg) {
    .header-wrap-inner {
        padding: 15px 0;
        border-bottom: 1px solid $border-color;
    }
}
