/* color */

.bg-blue {
    background: $color-info;
}

.bg-red {
    background: $color-red;
}

.bg-yellow {
    background: $color-yellow;
}

/* margin */

.m-t-30 {
    margin-top: 30px;
}

.m-r-30 {
    margin-right: 30px;
}

.m-r-20 {
    margin-right: 20px;
}

/* padding */

.padding-tb-75 {
    padding-top: 75px;
    padding-bottom: 75px;
}

.p-t-5 {
    margin-top: 5px;
}
