.slick-list {
    .slick-track {
        float: left;
    }
}

.slick-dotted {
    &.slick-slider {
        margin-bottom: 0;
    }
}

.slick-prev,
.slick-next {
    z-index: 1;

    &:before {
        font-family: 'Line Awesome Free';
        font-size: 14px;
        font-weight: 900;
        color: $color-gray-dark;
        opacity: 1;
        transition: $transition-default;
    }

    &:hover {
        &:before {
            color: $color-default;
            color: var(--color-primary);
        }
    }
}

.slick-prev:before {
    content: '\f104';
}

.slick-next:before {
    content: '\f105';
}

.rtl {
    .slick-prev:before {
        content: '\f105';
    }

    .slick-next:before {
        content: '\f104';
    }
}

.slick-lightbox {
    .slick-prev,
    .slick-next {
        &:before {
            font-size: 24px;
        }
    }

    .slick-lightbox-close {
        right: 13px;

        &:before {
            font-family: 'Line Awesome Free';
            font-weight: 900;
            font-size: 20px;
            content: '\f00d';
            transition: $transition-default;
        }

        &:hover {
            &:before {
                color: $color-default;
                color: var(--color-primary);
            }
        }
    }
}

.slick-dots {
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -12px;

    li {
        height: 10px;
        width: 10px;
        margin: 0 8px 12px;
        transition: $transition-primary;

        button {
            height: 10px;
            width: 10px;
            padding: 0;
            transition: $transition-primary;

            &:before {
                content: '';
                font-size: 14px;
                height: 10px;
                width: 10px;
                background: $color-default;
                background: var(--color-primary);
                border-radius: 5px;
                transition: $transition-primary;

            }
        }

        &.slick-active {
            width: 30px;

            button {
                width: 30px;

                &:before {
                    width: 30px;
                    opacity: 1;
                }
            }
        }
    }
}

.arrow-prev.slick-arrow,
.arrow-next.slick-arrow {
    position: absolute;
    font-size: 14px;
    padding: 4px 0;
    color: $color-gray-dark;
    z-index: 1;
    cursor: pointer;
    transition: none;

    &:hover {
        font-weight: 500;
        color: $color-default;
        color: var(--color-primary);
    }
}

.arrow-prev {
    right: 69px;
}

.arrow-next {
    right: -3px;
}
