.product-search-left {
    width: 263px;
    min-width: 263px;
    margin: -6px 30px 0 0;

    .section-title {
        margin-bottom: 24px;
        padding-bottom: 13px;
        border-bottom: 1px solid $border-color-lite;
    }

    .vertical-products {
        margin-top: 29px;

        .section-title {
            margin-bottom: 0;
            border-bottom: none;
        }
    }
}

@media screen and (max-width: 1400px) {
    .product-search-left {
        width: 246px;
        min-width: 246px;
    }
}

@media screen and (max-width: $lg) {
    .product-search-left {
        width: 100%;
        min-width: 0;
        margin: 0;

        .vertical-products {
            margin-top: 44px;
        }
    }
}
