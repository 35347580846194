.is-countdown {
    border: none;
    background: transparent;

    .countdown-row {
        display: flex;
        padding: 0;
        justify-content: center;
    }

    .countdown-show4 {
        .countdown-section {
            width: auto;
        }
    }

    .countdown-section {
        position: relative;
        float: none;
    }

    .countdown-amount {
        font-size: 16px;
        line-height: 22px;
        display: flex;
        margin: 0 auto 26px;
        align-items: center;
        justify-content: center;
    }

    .countdown-period {
        position: absolute;
        font-size: 12px;
        line-height: 22px;
        left: 50%;
        bottom: 0;
        color: $color-gray;
        transform: translateX(-50%);
    }
}
