.alert {
	font-size: 16px;
	position: fixed;
	right: 15px;
	bottom: 15px;
	display: flex;
	margin: 0 0 0 15px;
	padding: 20px 64px 20px 28px;
	align-items: center;
	border: none;
	border-left: 3px solid;
	border-radius: $radius-default;

	> i {
		font-size: 20px;
		margin-right: 16px;
	}

	.close {
		font-size: 16px;
		top: 50%;
		right: 17px;
		padding: 10px;
		opacity: 1;
		transform: translateY(-50%);

		&:hover {
			> i {
				color: $color-default;
                color: var(--color-primary);
			}
		}

		> i {
			color: $color-gray-dark;
		}
	}
}

.alert-success {
	color: $color-green;
	background: #e8f7f3;
	border-color: $color-green;
}

.alert-danger {
	color: $color-red;
	background: #fbebea;
	border-color: $color-red;
}

.alert-info {
	color: $color-info;
	background: #e2ecf7;
	border-color: $color-info;
}

.alert-warning {
	color: $color-yellow;
	background: #fef5ea;
	border-color: $color-yellow;
}
