.order-details-middle {
    margin-bottom: 25px;

    .table-responsive {
        border: 1px solid $color-gray-lite;
        border-radius: $radius-default;
    }
}

.order-details-table {
    tbody {
        td {
            &:nth-child(2),
            &:nth-child(4) {
                min-width: 170px;
            }
        }
    }

    .product-name {
        display: inline-block;
        color: $color-black;

        &:hover {
            color: $color-default;
            color: var(--color-primary);
        }
    }

    .product-options {
        margin: 4px 0 1px;
    }

    .product-price {
        color: $color-black;
    }
}

@media screen and (min-width: 881px) {
    .order-details-table {
        td {
            > label {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 880px) {
    .order-details-middle {
        .table-responsive {
            padding-bottom: 4px;
        }
    }

    .order-details-table {
        thead {
            display: none;
        }

        tr {
            &:first-child {
                td {
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        padding-top: 2px;
                    }
                }
            }
        }

        tbody {
            td {
                vertical-align: top;
                display: block;

                &:nth-child(1) {
                    padding: 25px 30px 11px !important;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    padding: 2px 30px;
                    white-space: nowrap;
                }

                &:nth-child(4) {
                    padding: 2px 30px 3px;
                    white-space: nowrap;
                }
            }
        }

        td {
            > label {
                width: 90px;
                min-width: 90px;
                margin-bottom: 0;
                padding-right: 5px;
                color: $color-gray;
                white-space: pre-wrap;
            }
        }

        .product-price {
            display: inline-flex;
        }
    }
}

@media screen and (max-width: $sm) {
    .order-details-table {
        tbody {
            td {
                &:nth-child(1) {
                    padding: 25px 20px 11px !important;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    padding: 2px 20px;
                }

                &:nth-child(4) {
                    padding: 2px 20px 3px;
                }
            }
        }
    }
}
