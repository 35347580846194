.order-summary-middle {
    padding: 25px 0 8px;

    &.loading {
        &:before {
            left: -15px;
            top: 15px;
            right: -15px;
            bottom: 15px;
        }
    }

    .shipping-methods {
        margin: 21px 0 -6px;

        > h6 {
            margin-bottom: 17px;
        }

        .error-message {
            margin: 0;
            padding-bottom: 10px;
        }

        .form-group {
            margin: -1px 0 0;
        }

        .form-radio {
            display: flex;
            justify-content: space-between;

            label {
                padding-right: 20px;
                color: $color-black;
            }
        }
    }
}
